html,
body,
#root {
    background-color: #fff;
    color: #000;
}

.card {
    font-weight: 300 !important;
}

::placeholder {
    color: #c0c0c0 !important;
}

:-ms-input-placeholder {
    color: #c0c0c0;
}

 ::-ms-input-placeholder {
    color: #c0c0c0;
}